import React, { useState, useMemo } from "react";
import Image from "next/image";
import { StatusAuthTypes } from "helpers/auth.types";
import { usePostHog } from "posthog-js/react";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";
import { useTranslation, TFunction } from "next-i18next";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { Input } from "components/atoms/form/input/input.component";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validators } from "lib/validators/validators";
import { FormError } from "components/atoms/form/error/error.component";
import { setNotificationsWTN } from "helpers/wtn/notifications";
import { useCheckDevice } from "hooks/use-check-device/use-check-device";
import { setIdentifyUserId } from "helpers/wtn/identify";
import { trackingLogin } from "helpers/wtn/firebaseEntrance";
import { MixpanelTypes } from "helpers/mixpanel/types";
import { mixpanelVariant } from "helpers/mixpanel";
import { dataLayerLogin } from "helpers/dataLayer/entrance";
import {
    StyledImageGoogle,
    StyledTextGoogle,
    StyledInputContainer,
    StyledButtonForm,
    StyledButtonBack,
} from "./twoFa.styled";
import { logRequest, logResponse, logError } from "helpers/loggerData";

interface IProps {
    onBack: (type: boolean) => void;
    rememberMe: boolean;
}

export const TwoFaModule: React.FC<IProps> = ({ onBack, rememberMe }) => {
    const [__] = useTranslation();
    const schema = useSchema(__);
    const { mobile, device } = useCheckDevice();
    const posthog = usePostHog();
    const [disabled, setDisabled] = useState(false);

    const { defaultLocale, locale } = useRouter() || {};
    const callbackUrl = (defaultLocale && locale && defaultLocale !== locale) ? `/${locale}` : "/";

    const [formError, setFormError] = useState(false);

    const { handleSubmit, formState, register } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema),
        reValidateMode: "onBlur",
    });

    const onSubmit = handleSubmit(async ({ code }) => {
        setDisabled(true);
        const logData = {
            desc: "Check Login 2FA-code",
            method: "POST",
            url: "/client-api/2fa-check",
        };
        const { url, method } = logData;

        try {
            const body = {
                _auth_code: code,
                _trusted: "on",
                rememberMe,
            };

            logRequest(logData, body);

            const res = await fetch(url, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method,
                body: JSON.stringify(body),
            });

            logResponse(logData, res);

            const user = await res.json();

            if (user.error) {
                setFormError(true);
                setDisabled(false);
            } else if (res.ok) {
                if (mobile) {
                    setNotificationsWTN(user.client.id, device);
                }
                mixpanelVariant(MixpanelTypes.LOGIN, user.client.id, user.client.country);
                posthog?.identify(`${user.client.id}`,
                    {email: user.client.email}
                );
                posthog?.capture("sign_in");


                setIdentifyUserId(user.client.id);
                trackingLogin(user.client.isVerified);
                dataLayerLogin(
                    user.client.id,
                    user.client.isVerified
                );

                await signIn("login", {
                    exp: user.exp,
                    expDate: user.expDate,
                    accessToken: user.accessToken,
                    id: user.client.id,
                    isVerified: user.client.isVerified,
                    firstDepositId: user.client.firstDepositId,
                    registrationDate: user.client.registrationDate,
                    emailVerified: user.client.emailVerified,
                    status: StatusAuthTypes.LOGIN,
                    callbackUrl,
                });
            } else {
                setFormError(true);
                setDisabled(false);
            }
        } catch (error: unknown) {
            console.error(error);
            logError(logData, error);
        }
    });

    return (
        <>
            {
                formError &&
                <FormError
                    text={__("auth.twoFA.incorrect-auth-code")}
                    onClick={() => setFormError(false)}
                />
            }
            <StyledImageGoogle>
                <Image
                    src="/svg/separate/authenticator.svg"
                    alt=""
                    layout="fill"
                />
            </StyledImageGoogle>
            <StyledTextGoogle variant={TypographyTypes.LARGE}>
                {__("auth.twoFA.enter-code")}
            </StyledTextGoogle>
            <form onSubmit={onSubmit}>
                <StyledInputContainer>
                    <Input
                        labelText={__("auth.twoFA.authentication-code")}
                        type="text"
                        {...register("code")}
                        error={formState.errors.code?.message}
                    />
                </StyledInputContainer>
                <StyledButtonForm
                    value={__("components.continue")}
                    disabled={disabled}
                />
                <StyledButtonBack onClick={() => onBack(false)}>{__("components.back")}</StyledButtonBack>
            </form>
        </>
    );
};

function useSchema(__: TFunction) {
    return useMemo(
        () =>
            yup.object().shape({
                code: validators(__).default,
            }),
        [__],
    );
}
