import React from "react";
import { useRouter } from "next/router";
import { ButtonTypes, ButtonIcon } from "components/atoms/button-link/button-link.types";
import { ButtonLink } from "components/atoms/button-link/button-link.component";
import { useGoogleLogin } from "@react-oauth/google";
import { MixpanelTypes } from "helpers/mixpanel/types";
import { mixpanelVariant } from "helpers/mixpanel";
import { socialAuth, IAuthParams, ISocialStatus } from "lib/hooks/social/social-auth";
import { signIn } from "next-auth/react";
import { IConfig } from "types/next-auth";
import { useModals } from "components/providers/modals/modals.provider";
import { StatusAuthTypes } from "helpers/auth.types";

export const GoogleSocial: React.FC = () => {
    const { defaultLocale, locale } = useRouter() || {};
    const { openConnectModal } = useModals();

    const authHandler = async (data: IConfig) => {
        const callbackUrl = (defaultLocale && locale && defaultLocale !== locale) ? `/${locale}` : "/";
        mixpanelVariant(MixpanelTypes.LOGIN, data.client.id, data.client.country);
        await signIn("login", {
            exp: data.exp,
            expDate: data.expDate,
            accessToken: data.accessToken,
            id: data.client.id,
            isVerified: data.client.isVerified,
            firstDepositId: data.client.firstDepositId,
            registrationDate: data.client.registrationDate,
            emailVerified: data.client.emailVerified,
            status: StatusAuthTypes.LOGIN,
            callbackUrl,
        });
    };

    const handlerSocialLogin = useGoogleLogin({
        onSuccess: (credentialResponse) => {
            const bodyAuth: IAuthParams = {
                parameters: credentialResponse,
                redirectUrl: window.location.origin,
                rememberMe: true,
            };
            void socialAuth("google", bodyAuth).then((data) => {
                switch(data?.status) {
                    case ISocialStatus.SUCCESS: return data.user && void authHandler(data.user);
                    case ISocialStatus.ERROR: return openConnectModal();
                }
            });
        },
        onError: (error) => console.log("Login Failed:", error),
        scope: "openid profile email",
        flow: "auth-code",
    });

    return (
        <ButtonLink
            variant={ButtonTypes.SOCIAL}
            onClick={() => handlerSocialLogin()}
            icon={ButtonIcon.GOOGLE}
        />
    );
};
